<template>
	<div>
		<searchCard>
			<div class="flex">
				<vSelect class="w-1/6" v-model="opt.searchKeyName" :clearable="false" :options="searchKeyOpts" />
				<b-form-input
					class="w-1/3 mx-4"
					v-model.trim="opt.searchKeyword"
					name="keyword"
					placeholder="검색어"
					v-b-tooltip.hover.html
					@keyup.enter="search"
					title="안심번호 통합검색 대상 필드: 핫트아이디, 딜번호, 전화번호, 가상전화번호"
				/>
				<b-button class="px-12" @click="search">검색</b-button>
			</div>
		</searchCard>
		<b-card>
			<tb :inf="inf" :res="res" />
		</b-card>
	</div>
</template>

<script>
export default {
	props: { inf: { default: [] }, internalUrl: { default: '' }, mapping: { default: v => v } },
	data() {
		const defOpt = {
			searchKeyName: { label: '안심번호 통합검색', value: '' },
			searchKeyword: '',
		}
		return {
			searchKeyOpts: [
				{ label: '안심번호 통합검색', value: '' },
				{ label: '전화번호', value: 'phoneNumber' },
				{ label: '가상전화번호', value: 'virtualPhoneNumber' },
				{ label: '핫트아이디', value: 'hottId' },
				{ label: '딜번호', value: 'dealNum' },
			],
			opt: { ...defOpt },
			defOpt,
			lastOpt: {},
			res: {},
		}
	},
	methods: {
		search(operation = 'LIKE') {
			const opt = this.opt,
				searchKeyword = opt.searchKeyword
			if (searchKeyword && searchKeyword.length < 2) {
				alert.w('검색어를 두 글자 이상 입력하세요.')
				return
			}

			this.lastOpt = {
				paging: getPaging(),
			}

			if (searchKeyword) {
				let options = getDropdownValue(opt.searchKeyName)
				if (!options) options = this.searchKeyOpts
				this.lastOpt.searchKey = getSearchKey(options, searchKeyword)
			}
			this.changePage(1)
		},
		changePage(page = 1) {
			this.lastOpt.paging.pageNo = page - 1
			return postApi(this.internalUrl, this.lastOpt).then(res => {
				return this.mapping(this, res)
			})
		},
	},
	created() {
		this.search()
	},
}
</script>
